body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  width: 100%;
  height: 100%;
  position: absolute;;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-direction: column;
  overflow: hidden;
  background: black;
}

.App .ui.menu {
  flex: auto 0 0;
  margin-bottom: 0;
}
.toolbar.ui.menu:not(.vertical) .item {
  flex-direction: row-reverse;;
}
