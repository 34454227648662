.node .root{
  fill: #dddddd;
  stroke: black;
  stroke-weight: 2px;
}
.node .header {
  fill: #bbbbbb;
}
.node .body {
  position: relative;
}
.inputs .pin .display {
  position: absolute;
  left: 0;
  transform: translate(-50%,0);
}
.outputs .pin .display {
  position: absolute;
  right: 0;
  transform: translate(50%,0);
}

.node .field > * {
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;

}
