.editor {
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  position: relative;
}

.code-editor {
  min-width: 100;
  width: 100%;
  flex: 0 0 auto;
  position: relative;
}
.code-editor.minimized {
  min-width: 0;
  width: 0;
}

#CodeEditor {
  height: 100%;
  width: 100%;
}
.preview {
  flex: 1 1 auto;
  overflow: hidden;
}
.console {
  position: absolute;
  right: 10px;
  bottom: 20px;
  width: calc(50% - 40px);
}
.tab-menu.minimized {
  width: 0;
  min-width: 0;
}
.tab-menu {
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  position: relative;
  min-width: 500px;
    width: 50%;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative;
}
.tab-menu .icons {
  position: absolute;
  top: 10px;
  right: -30px;
}
.tab-menu .icons .icon-minimize {
  display: block;
  margin-bottom: 1rem;
  width: 30px;
  height: 30px;
  background: white;
  padding-top: 7px;
  padding-left: 5px;
  border-radius: 0 5px 5px 0;
}
