body, html, #root {
  height: 100%;
  width: 100%;
}

svg.surface {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffff;
}
svg.surface * {
  box-sizing: border-box;
}
